import { TranslatedKey } from "./translatedKey";
export const en: Record<TranslatedKey, string> = {
  aboutSectionContent1:
    "The web application replaces the previous desktop application that was downloaded to your computer. This means that you as a user now can access the eBVD-system directly through a webpage.",
  aboutSectionContent2:
    "In the top right corner you find the options to either create or log in to your existing eBVD-account. You can also search among published eBVDs and download them directly to your computer as pdf-files. To access more detailed search parameters click on the button “Search eBVD” above. You don’t need to log in to the system to be able to search among the published eBVDs. Information regarding system malfunctions or updates will be published on the start page under “System information”. User manuals and instructions for the eBVD-system can be found below. For further questions or comments about eBVD, please contact us at ",
  aboutSectionContent3: "Text 3",
  aboutSectionTitle: "About eBVD web",
  aboutSectionTitle2: "Integrity",
  actionLogin: "Sign in",
  actionLogout: "Sign out",
  addAttachment: "Add Attachment",
  addReference: "Add Reference",
  addUser: "Add user to your organization profile",
  articleAddItemNumber: "Add item number",
  articleAddProductGroup: "Add Product Group",
  articleAddSubComponentTitle: "Add article and/or sub component",
  articleAddSubComponentError: "Missing information in required field",
  articleAttachmentTitle: "Attachments",
  articleConceptTitle: "Article No/ID Concept",
  articleDeclarationNumber: "Declaration of Performance Number",
  articleDescription: "Article Description",
  articleEditSubComponentTitle: "Edit article and/or sub component",
  articleGTINTitle: "Why is no GTIN specified?",
  articleIDTableTitle: "Article ID",
  articleIdentitiesTableTitle: "Article identites",
  articleName: "Article Name",
  articleOtherInformation: "Other information",
  articleProductGroup: "Product group/ Product group classification",
  articleProductGroupID: "Product group ID",
  articleProductGroupSystem: "Product group system",
  articleProductRegulation:
    "Has a declaration of performance, according to the Construction Product Regulation, been drawn up for the product?",
  articleReferenceTitle: "References",
  articleSelectIdSystemNumber: "Select the ID system to be used:",
  articleSelectIdSystemNumberLabel: "number",
  articleSubComponentEditHphraseTableTitle: "Edit H-phrases",
  articleSubComponentExistingArticleTableTitle: "Add existing articles",
  articleSubComponentMaterialTableTitle: "Add Material",
  articleSubComponentTableAddTitle: "Add",
  articleSubComponentTableDeleteTitle: "Delete",
  articleSubComponentTableEditTitle: "Edit",
  articleSubComponentTableHide: "Hide",
  articleSubComponentTableShow: "Show",
  articleSubComponentTableSubstanceTitle: "Add Substance",
  articleSubComponentTableTitle:
    "Specify which components, materials and substances the product/chemical product consists of. Always specify the composition of the product/chemical product as both delivered and mounted. If the composition differs from delivery to mounted, the contents shall be specified for each phase separately, otherwise it is possible to use the phase Delivery/Mounted.",
  articleVATNumber: "Internal article name",
  articleVATNumberLabel: "VAT-number",
  attachmentBlockTitle: "Attachment",
  back: "Back",
  bulkManagementDeleteTitle: "Remove",
  bulkManagementExportPdfTitle: "Export PDF",
  bulkManagementExportXMLTitle: "Export XML",
  bulkManagementExportExcelTitle: "Export Excel",
  bulkManagementHistoryTitle: "History of publishing and unpublishing",
  bulkManagementPublishTitle: "Publish document",
  bulkManagementUnpublishTitle: "Unpublish document",
  bulkManagementTitle: "Bulk Management",
  buttonVerify: "Verify",
  canRecycle: "Is material recovery possible for the whole or parts of the article when it is replaced/discarded?",
  cancelButtonText: "Cancel",
  cantPublishDocuments:
    "It is not possible to publish this document(s). Either there is a lack of authorization for this or it/they are already published",
  casCheckSumErrorMessage: "The checksum is not correct for the CAS number",
  checmicalComponentAlternateCodeTableTitle: "AlternativeCode",
  checmicalComponentECTableTitle: "EC",
  checmicalComponentHphraseTableTitle: "H-phrases",
  chemicalComponentCASTableTitle: "CAS",
  chemicalComponentConcentrationTableTitle: "Concentration Interval",
  chemicalComponentPhaseTableTitle: "Phase",
  chemicalComponentTitle: "Name",
  chamicalComponentComponentMaterialSubstanceTitle: "Component/Material/Substance",
  chemicalComponentPhasingTableTitle: "Phasing-out Substance",
  chemicalComponentRouteofExposureTableTitle: "Route of Exposure/Organs",
  chemicalComponentSubstanceTableTitle: "Substance",
  chemicalComponentTableComponentTitle: "Component",
  chemicalComponentTableMaterialTitle: "Material",
  chemicalContentArticle: "Article and/or Sub-Components",
  chemicalContentArticleSubComponentTableTitle: "Article and/or sub-components",
  chemicalContentCandidateListEdition: "Enter which version of the candidate list has been used (Year, Month, Day)",
  chemicalContentCandidateListTableTitle: "Candidate list",
  chemicalContentCommentTableTitle: "Comment",
  chemicalContentDeclaredWeightComment: "If 100% material content is not declared, enter the reason",
  chemicalContentDeclaredWeightPercentage:
    "Enter how large a proportion of the material content has been declared [weight-%]",
  chemicalContentDescription1:
    "Chemical product is a chemical substance or a preparation of chemical substances that are not a commodity (definition according to chapter 14, section 2 of the Environmental Code (1998:808)).",
  chemicalContentDescription2:
    "A product is an object which, during production, receives a specific shape, surface or design, which determines its function to a greater extent than its chemical composition (definition according to REACH, Chapter 2, Article 33).",
  chemicalContentDescription3:
    "Chemical products shall be declared in accordance with the same principle as in safety data sheets, see REACH Annex II, 3.2.1 to 3.2.4. In addition, all substances included in ≥2% must be declared (also applies to unclassified substances).",
  chemicalContentDescription4:
    "Enter the chemical content. For composite goods, the chemical content shall be indicated at component level according to the 'once always be' principle from the chemical legislation REACH Art 33.",
  chemicalContentDescription5:
    "In the case of goods,the same accounting requirements apply as for mixture not classified as hazardous,with the exception of cases where the presence of the substance at a level lower than that of the accounting requirement would have led to a mixture being classified as dangerous.In these cases, it is that content that would have led to a mixture being classified as dangerous that constitutes the content limit.",
  chemicalContentDescription6:
    "In addition, all substances included in >=2% must be declared(also applies to unclassified substances)",
  chemicalContentHasProductClassification: "Is the chemical product as a whole classified?",
  chemicalContentHasSafetyDatasheet: "Is there a Safety Data Sheet?",
  chemicalContentInformation: "Other information",
  chemicalContentProductClassification:
    "If Yes, indicate the classification of the product under Regulation (EC) No 1272/2008) by Hazard category (abbreviated), H-phrase",
  chemicalContentProductContentTypeId: "Does the declaration apply to a product or chemical product?",
  chemicalContentProductNanoMaterial:
    "Do the chemical product contain nanomaterials that are deliberately added to achieve a certain function, please specify these below",
  chemicalContentProductNanoMaterialIsRegistered:
    "Has the presence of nanomaterials deliberately added to notifiable chemical products been reported to the Product Register?",
  chemicalContentProductVolatileOrganicPart:
    "Enter the proportion of volatile organic substances [g/litre], only relevant for sealants, paint, lacquer, adhesives and solvents.",
  chemicalContentRoHS: "The product is covered by the RoHS Directive (2011/65/EU)",
  chemicalContentWeight: "Enter the weight of the article",
  chemicalExistingArticle: "Article",
  chemicalExistingButtonName: "Add component",
  chemicalExistingDialogTitle: "Add existing component",
  chemicalExistingPhase: "Phase",
  chemicalExistingSelected: "Selected",
  chemicalExistingTradeName: "TradeName",
  clearFilterText: "Clear Filter",
  close: "Close",
  companyDigitalTransferTitle: "Digital transfer of eBVD through web-API",
  companyDigitalTransferText:
    "Digital transfer through API between the eBVD-system and other databases makes it efficient to both provide as well as use environmental information for construction materials. The standard setting in the eBVD-system is that digital transfer of non-confidential information in published eBVDs to other databases is approved. You have, of course, continued control of your product information and can regulate if eBVD i Norden AB are allowed to share your published eBVDs, and their content, with the connected organizations or not. The administrator of your company can handle and deactivate the digital transfer under the tab “Digital transfer”. ",
  constructionPhaseComment: "Other information",
  contactSectionAddress1: "Box 5501, 114 85 STOCKHOLM",
  contactSectionAddress2: "Visiting address: Storgatan 19",
  contactSectionAddressTitle: "The building materials industries",
  contactSectionAddressIvl: "Valhallavägen 81, 114 28 Stockholm",
  contactSectionContactIvl: "Contact: eBVD@ivl.se",
  contactSectionInfoIvl1: "eBVD i Norden AB is a subsidiary to ",
  contactSectionInfoIvlLink: "IVL Swedish Environmental Research Institute",
  contactSectionInfoIvl2: "and the Swedish Association for Construction Products.",
  contactSectionAddressTitleIvl: "IVL Swedish Environmental Research Institute",
  contactSectionLink1: "User instructions",
  contactSectionLink2: "Support",
  contactSectionLink3: "Frequently asked questions",
  containsWood: "Wood raw material included?",
  copyDocumentButton: "Copy eBVD",
  copyDocumentInformation: "Copy the following information:",
  coveredByRegulation:
    "Is the supplied article covered by the Ordinance (2014:1075) on producer responsibility for electrical and electronic products when it becomes waste?",
  createEbvdButton: "Create eBVD",
  d6866: "Ingredient bio-based raw material is tested according to ASTM test method D6866",
  deleteUserTitle: "Delete User",
  deleteUserMessage: "Are you sure that you want to remove the user ",
  deleteDocumentTitle: "Delete eBVD : ",
  deleteDocumentMessage: "Are you sure?",
  deleteProductContentComponentMessage: "Are you sure?",
  deleteProductContentComponentTitle: "Delete article or sub component:",
  deliveredArticleSession: "Delivered Article",
  demolitionCanBeSeparated: "The product can be separated into pure types of material for material recycling?",
  demolitionComment: "Other information",
  demolitionPrepared: "Is the article prepared for dissasembly (dismantling)?",
  demolitionPreparedComment: "If yes on any of the questions above, please specify which materials:",
  demolitionSafety:
    "Does the product require special measures for the protection of human health and/or the environment in the event of demolition, dismantling or separating materials?",
  demolitionSafetyComment: "If yes, please specify",
  digitalTransferInformationAccess: "Information Access",
  digitalTransferName: "Name",
  digitalTransferSeleted: "Selected",
  distributionCanPackagingBeEnergyRecycled: "Can the packaging be energy recycled?",
  distributionCanPackagingBeMaterialRecycled: "Can the packaging be recycled?",
  distributionCanPackagingBeReused: "Can packaging be reused?",
  distributionComment: "Other information",
  distributionHasPackagingSystem:
    "Is the supplier affiliated to a system for producer responsibility for packaging? (applies to the product in question)",
  distributionPackagingSystem: "If, yes which packaging and which system?",
  distributionReclaimPackaging: "Does the supplier take back packaging for the product?",
  distributionReturnSystem: "Does the supplier offer pallets with return-system option?",
  distributionReusablePackaging: "Does the supplier apply systems with multiple-use packaging for the product?",
  distributionSection: "Distribution of Finished Article",
  documentCancel: "Cancel",
  documentCommentPublish: "The eBVD is published and cannot be modified",
  documentCommentSaved: "The document is saved but not published",
  documentCreateNeweBVD: "Create new eBVD",
  documentCreated: "Created",
  documentLastsaved: "Last saved",
  documentLatestPublished: "Latest Published Version",
  documentLatestVersion: "Latest Version",
  documentModalDocumentIDSystem: "Enter document ID System",
  documentModalOrganisation: "Organisation",
  documentModalProductName: "Article name",
  documentModalTemplate: "Template",
  documentModalTitle: "Create new eBVD",
  documentModalValidFrom: "Valid From",
  documentOrganisationErrorMessage: "Organisation Required",
  documentProductNameErrorMessage: "ProductName Required",
  documentPublished: "Published",
  documentAboutToPublish: "You are about to publish ",
  documentConfirmPublishing: "Confirm publishing",
  documentPublishNoMoreChanges:
    "Please note that no further changes will be able to be made to this document after it has been published. To make changes, a new version of the document needs to be created.",
  documentsPublishNoMoreChanges:
    "Please note that no further changes will be able to be made to these documents after they have been published. To make changes, a new version of the documents needs to be created.",
  documentPublishAgreement1:
    "I certify that the content declaration is reported in accordance with the documentation requirements.",
  documentPublishAgreement2:
    "I certify that the content of candidate substances, substances in the authorization list (REACH Annex XIV) and substances which are restricted according to REACH Annex XVII and which are included in concentrations of 0.1% or more are fully reported as per ",
  documentReference: "References",
  documents: "documents",
  documentStatus: "Status",
  documentStatusChanged: "Status Changed",
  documentStatusFilterChip: "Document Status: ",
  documentStatusPublish: "Published",
  documentStatusSaved: "Created",
  documentSystemCodeErrorMessage: "Document SystemCode Required",
  documentSystemIDErrorMessage: "Document ID Required",
  documentValidFrom: "Valid from",
  documentValidTo: "Valid to",
  documentVersion: "Version",
  documentVersionLowercase: "version ",
  documentWasteCodeDelivereds: "Enter waste code for the delivered article when it becomes waste:",
  documentWasteCodeMounteds: "Enter waste code for the mounted article",
  eBVDDocumentSaveMessage: "eBVD saved.",
  ecCheckSumErrorMessage: "The checksum is not correct for the EC number",
  editAttachment: "Edit Attachment",
  editReference: "Edit Reference",
  energyRecovery: "Is energy recovery possible for the whole or parts of the article when it is replaced/discarded?",
  environmentalImpactEN15804:
    "Has environmental product declaration been drawn up according to EN 15804 or ISO 14025 for the article?",
  environmentalImpactEPD: "Registration Number / Id Number for EPD:",
  environmentalImpactPCR: "If yes, what product-specific rules have been used, so-called PCR (Product Category Rules):",
  environmentalLifecyclePerspective:
    "If environmental product declaration or life cycle assessment is missing, describe how the environmental impact of the product is taken into account from a life cycle perspective:",
  errorMessage: "Something went wrong, if problem remains please contact an administrator",
  errorMessageTitle: "Error Message",
  errorMessageDescription:
    "Information is missing in required fields, please add the missing information to be able to publish your document.",
  errorRequiredMessage: "Required",
  errorTryAgainButton: "Try again",
  existingArticleComponentItem: "Existing Component",
  exportError: "Error on Export: ",
  fetchingData: "Fetching data.. Please wait",
  fieldAddress: "Address",
  fieldAlternativeCode: "Alternative code",
  fieldAreaCode: "Area Code",
  fieldAuthorisationList: "Substance on authorisation list",
  fieldCASNum: "CAS No",
  fieldCadmium: "Cadmium (Cd) or lead compound",
  fieldCandidateList: "Substance on candidate list",
  fieldCity: "City",
  fieldComment: "Comment",
  fieldCompanyAccounting: "If yes, set accounting policies/defaults",
  fieldCompanyAction: "Action plan",
  fieldCompanyFollowUpPlan: "Follow-up plan",
  fieldCompanyGuidelines1:
    "Which of the following guidelines have you affiliated to or which management system have you implemented?",
  fieldCompanyGuidelines2: "UN guiding principles for companies and human rights",
  fieldCompanyILO: "ILO's eight core conventions",
  fieldCompanyISO26000: "ISO 26000",
  fieldCompanyMapping: "Mapping",
  fieldCompanyName: "Company Name",
  fieldCompanyOECD: "OECD Guidelines for Multinational Enterprises",
  fieldCompanyOtherGuidelines: "Other policy guidelines",
  fieldCompanyPolicy:
    "The company has a code of conduct/policy/guidelines for dealing with corporate social reponsibilty in the supplier chain,including procedures for ensuring that the requirements.",
  fieldCompanyResponsibility:
    "If you have a management system for corporate social responsibility ,what out of the following is included in the work?",
  fieldCompanyReviewed: "This is third-party reviewed",
  fieldCompanyRisk: "Risk analysis",
  fieldCompanySustainability: "Does the company have an updated sustainability report?",
  fieldCompanyUN: "UN Global Compact",
  fieldComponent: "Article/Sub-Component",
  fieldConcentrationRange: "Concentration Range",
  fieldContact: "Contact",
  fieldCorporateIDNO: "Corporate ID NO",
  fieldCountry: "Country",
  fieldDUNS: "DUNS",
  fieldDocDataChangeDesc: "Change Relates To:",
  fieldDocDataCreated: "Created",
  fieldDocDataPublished: "Published",
  fieldDocDataSaved: "Last Saved",
  fieldDocDataSerialNumber: "Serial Number",
  fieldDocDataStatus: "Status",
  fieldDocDataStatusComment: "Status Comment",
  fieldDocDataSystemCode: "DUNS Number",
  fieldDocDataSystemID: "Enter Document ID System",
  fieldDocDataValidFrom: "Valid From",
  fieldDocDataValidTo: "Valid To",
  fieldDocDataVersionNumber: "Version",
  fieldECNum: "EC No",
  fieldEMail: "E-Mail",
  fieldEndocrineDisruptor: "Endocrine disruptor",
  fieldGLN: "GLN",
  fieldHPhase: "H-phrases",
  fieldLead: "Lead (Pb) or lead compound",
  fieldLimitationList: "Substance on limitation list",
  fieldLinkToLatestVersion: "Link to latest version",
  fieldLogo: "Logo",
  fieldLogoValue: "Change logotype by clicking the button",
  fieldMaterial: "Material",
  fieldMaxWeight: "Max Weight [%]",
  fieldMercury: "Mercury (Hg) or mercury compound",
  fieldMinWeight: "Min Weight [%]",
  fieldNOECNumorCASNum: "if CAS or EC is not specified, specify why",
  fieldOther: "Other",
  fieldPBT: "PBT",
  fieldPhase: "Phase",
  fieldPhasingOutProperties: "Substance with phasing-out properties",
  fieldRoute: "Route of exposure/organs",
  fieldSubstance: "Substance",
  fieldSysInfoDocumentBvdId: "ID",
  fieldSysInfoDocumentId: "Internal ID",
  fieldSysInfoLatestPdfFileAddress: "PDF Path",
  fieldSysInfoName: "Owner",
  fieldTelephone: "Telephone",
  fieldUserDisplayName: "Display Name",
  fieldUserEmail: "User E-mail",
  fieldUserRole: "Specify user role",
  fieldVATNumber: "VAT Number",
  fieldWebsite: "Website",
  fielddocumentAttachment: "Enter URL of the document you refer to",
  fielddocumentReference: "Enter information on the document you refer to",
  fieldsavedDate: "Company was last saved",
  fieldvPvB: "vPvB",
  filterShowLess: "Show less",
  filterShowMore: "Show more",
  footerText: "Co-owned by",
  frontPageAdminButton: "Admin",
  frontPageDocumentButton: "Manage eBVD",
  frontPageImportButton: "Import eBVD",
  frontPageNewDocumentButton: "Create new eBVD",
  hasRawMaterialOriginFossil: "For this product, has there been any extraction of virgin fossil material?",
  hasRequirementsOnSurroundingBuildingProducts:
    "Does the article have special requirements for surrounding building products?",
  hasStorageRequirements: "Does the article have special requirements in storage?",
  hazardousWasteDelivered: "When the supplied article becomes waste, is it classified as hazardous waste? ",
  hazardousWasteMounted: "Is the mounted article classified as hazardous waste?",
  headerSearchPlaceholder: "Search producht, article ID or GTIN",
  ifYesSpecify: "If yes, please specify",
  iHaveReadIntegrityPolicy: "Yes, I have read and accept eBVD's ",
  integrityPolicy: "privacy policy",
  importButton: "Import",
  importRefetchListTooltip: "Refetch list",
  importingTooltip: "Importing",
  importDownloadExcelTemplateTooltip: "Download Excel template",
  importDocumentsTooltip: "Import ebvd via file",
  importUploadFile: "Upload a File",
  importDragAndDrop: "Drag and drop a file here, or click to select one",
  importDocumentsModalTitle: "Import eBVD/s",
  importDocuments: "Import eBVD/s",
  importFileBufferErrorMessage: "a file is required",
  importViaFile: "Import via fil",
  importViaFileInfoText:
    "Please note that the system will check the file for errors before starting the import to the system, the import was not initiated and no new data was added to the system if an error message is displayed in the list below. Please correct the file according to the displayed error message before uploading the file again.",
  indexPageTitle: "Start page",
  indoorDays: "Days",
  indoorEmissionAdd: "Add emission",
  indoorEmissionEdit: "Edit emission",
  indoorEnvironmentElectricityFieldBlock: "Electrical Fields",
  indoorEnvironmentEmissioner: "Emissions",
  indoorEnvironmentEmissionerTableTitle:
    "The article produces the following emissions in intended use: Information is provided in accordance with EN 16516: 2017",
  indoorEnvironmentEnterYes: "If yes, enter which",
  indoorEnvironmentMagneticFieldBlock: "Magnetic Fields",
  indoorEnvironmentMesauringMethod: "Measuring method",
  indoorEnvironmentMoistureLevel: "Does the product have a critical moisture level?",
  indoorEnvironmentNoiseBlock: "Noise",
  indoorEnvironmentNotEmissionMeasure: "Emission has not been measured for the product",
  indoorEnvironmentNotIntended: "The product is not intended for indoor use",
  indoorEnvironmentNotProduceEmission: "The product emits no emissions",
  indoorEnvironmentOtherInformation: "Other information",
  indoorEnvironmentProductElectricField: "Can the product give rise to electrical fields?",
  indoorEnvironmentProductFungusBlock: "Paints and Varnishes",
  indoorEnvironmentProductMagneticField: "Can the product give rise to magnetice fields?",
  indoorEnvironmentProductNoise: "Can the product give rise to own noise?",
  indoorEnvironmentProductResistantToFungus: "The product is resistant to fungus and algae when used in wet rooms",
  indoorEnvironmentTableEmissionMeasuringInterval1: "Measuring interval 1",
  indoorEnvironmentTableEmissionResult1: "Result 1",
  indoorEnvironmentTableEmissionType: "Emmision type",
  indoorEnvironmentTableMeasuringInterval2: "Measuring interval 2",
  indoorEnvironmentTableMeasuringMethod1: "Measuring Method 1",
  indoorEnvironmentTableMeasuringMethod2: "Measuring method 2",
  indoorEnvironmentTableResult2: " Result 2",
  indoorEnvironmentUnit: "Unit",
  indoorEnvironmentValue: "Value",
  indoorHours: "Hours",
  indoorModalEmissionTitle: "Type of emission",
  indoorModalMeasuringMethodStandardTitle1: "Measuring method/standard 1",
  indoorModalMeasuringMethodStandardTitle2: "Measuring method/standard 2",
  indoorModalMeasuringPoint1: "Mesuring point 1",
  indoorModalMeasuringPoint2: "Mesuring point 2",
  indoorModalResult1: "Result 1",
  indoorModalResult2: "Result 2",
  indoorModalUnit1: "Unit 1",
  indoorModalUnit2: "Unit 2",
  indoorTimeIntervalMeasurement1: "Timing interval for emission measuremnt 1",
  indoorTimeIntervalMeasurement2: "Timing interval for emission measuremnt 2",
  indoorWeeks: "Weeks",
  languageEN: "English",
  languageSV: "Swedish",
  latestVersiontext: "Latest",
  linktoWebsite: "To the website",
  loadMoreBtnText: "Load More",
  loggingCountry: "Country of Logging",
  loggingLegal: "The timber has been harvested in a legal and certified way",
  loginMessage: "Logging in..",
  menuCopy: "Copy",
  menuCreateXML: "Create XML",
  menuDelete: "Delete",
  menuShowVersions: "Show all versions",
  name: "Name",
  newslistSectionTitle: "System information",
  newsTitle1: "The eBVD Windows application will be taken out of service",
  newsTitle2: "Simplified management of digital transfer through API",
  newsTitle3: "Launch of new funcion",
  newsDesc1:
    "In some cases materiala that was added in the table of chemical content in the previous desktop application with added substance information e.g. CAS/EC-number. This has blocked the possibility to publish the eBVD containing the above mentioned information on the web. These materials are now visible as substances in the table in order to enable editing of the previously added information and publish the affected eBVD. Please contact the support if you have any questions or need more information.",
  newsDesc2:
    "In order to make it easier to have control over your own data, we have adjusted the choices regarding digital transfer through the API. Data that is both non-confidential and published are shown in an equal manner in both our open search function and through our API. In other words, we have removed the option of sharing data that is confidential and/or not published to the organizations connected to our API. This is done to avoid that data is shared in a manner that is not mutually agreed in a company. For more information regarding the organizations getting data through our API, see the tab 'Digital transfer' on the admin-page at ebvd.org. Please contact the support if you have any questions or need more information.",
  newsDesc3:
    "On November 28th, new functionality is added to the system. It is now possible to export and import data into the system through an excel file. You can now find a new short-cut (“Import eBVD”) at the start page from where you will reach the import function. To use the export function go to “Manage eBVD”, “Bulk Management” and finally ”Export Excel”.For more information about the new functionality in the system, go to “For information and user manuals about how to use the eBVD-system, as well as user terms and privacy policy” at the start page.",
  newsDescLink: "here.",
  noOptionsText: "Please enter search term",
  noResultText: "No results found for ",
  noResultTextSearchPage: "No results, please refine your search.",
  notWoodCITESAppendix: "Does not contain wood species or origin in CITES appendix for endangered species",
  notWoodCITESAppendixVersion: "What version of CITES has been used for the control?",
  of: "of",
  organizationListChoice: "Choose organization you want to administrate",
  organizationListforImportChoice: "Choose organization you want to import for",
  organizationListforTransactionChoice: "Choose organization you want to see history for",
  organizationSectionDigitalTransfer: "Digital Transfer",
  organizationSectionPublicAcces: "Public Access",
  organizationSectionInformation: "Information",
  organizationSectionUser: "User",
  organizationSectionUserInvitation: "User Invitation",
  originRawMaterial: "Origin of the Raw Material",
  phaseDelivery: "Delivery",
  phaseDeliveryOrMounted: "Delivery/Mounted",
  phaseMounted: "Mounted",
  productContentTypeChemicalProduct: "Only the substance level show as weight percentage of the products total weight.",
  productContentTypeProductText1: "Components you need to be able to fill in concentratcion of TOTAL product",
  productContentTypeProductText2: "Material you should be able to fill in concentration of COMPONENT",
  productContentTypeProductText3: "Substance you should be able to fill in concentraction of MATERIAL",
  radioButtonAdministrator: "Administrator",
  radioButtonAllInformation: "All information",
  radioButtonCannotread: "Cannot read confidential information",
  radioButtonCanread: "Can read confidential information",
  radioButtonChemicalProduct: "Chemical Product",
  radioButtonCoWorker: "Coworker",
  radioButtonCustomRole: "Custom Role",
  radioButtonMinimumInformation: "Minimum information",
  radioButtonNo: "No",
  radioButtonNotApplicable: "Not Applicable",
  radioButtonProduct: "Product",
  radioButtonSelectedInformation: "Selected information",
  radioButtonSubcontractor: "Subcontractor",
  radioButtonUsageFifteenYears: "15 Years",
  radioButtonUsageFiveYears: "5 Years",
  radioButtonUsageGreaterthanFiftyYears: ">50 Years",
  radioButtonUsageNotAvailable: "Not Available",
  radioButtonUsageTenYears: "10 Years",
  radioButtonUsageTwentyFiveYears: "25 Years",
  radioButtonYes: "Yes",
  rawMaterialCityOfProductionTitle: "City of Production",
  rawMaterialCityofRawMaterialsTitle: "City of raw materials",
  rawMaterialCommentTitle: "Comment",
  rawMaterialCountryOfProductionTitle: "Country of Production",
  rawMaterialCountryofRawMaterialsTitle: "Country of raw materials",
  rawMaterialModalArticleSubTitle: "Article/Sub-component",
  rawMaterialModalCityOfManufacturerTitle: "City of manufacture/production",
  rawMaterialModalCityRawMaterialTitle: "City of raw material extraction",
  rawMaterialModalCommentTitle: "Comment",
  rawMaterialModalConfidentialTitle: "Confidential",
  rawMaterialModalCountryOfManufacturerTitle: "Country of manufacture/production",
  rawMaterialModalCountryRawMaterialTitle: "Country of raw material extraction",
  rawMaterialModalMaterialTitle: "Material",
  rawMaterialModalTitle: "Add part/component",
  rawMaterialModalTransportTypeTitle: "Transport Type",
  rawMaterialOriginFossilPart: "If yes, indicate what percentage of the material in question (or the item?)",
  rawMaterialPurchaseSystem:
    "Is there supporting documentation for the raw materials for third-party certified system for control of origin, raw material extraction, manufacturing or recycling processes, or similar (for example BES 6001:2008, EMS certificate, USGBC Programme)? If yes, enter system(s)",
  rawMaterialPurchaseSystemlabel: "Purchase System",
  rawMaterialTableComponentTitle: "Component",
  rawMaterialTableMaterialTitle: "Material",
  rawMaterialTableTitle: "Add included raw materials in the article below",
  rawMaterialTransportTitle: "Transport",
  readMoreLink: "Read More",
  rename: "Rename",
  recentlyViewed: "Recently viewed documents",
  renewableMaterial: "Renewable Material",
  renewableMaterialShortPart: "Enter proportion of renewable material in the article",
  requirementsOnSurroundingBuildingProducts: "If yes, please specify",
  restrictionsAndRecommendations:
    "Does the supplier have restrictions and/or recommendation for reuse, material or energy recovery or landfilling?",
  reusable: "Is reuse possible for the whole or parts of the article when it is replaced/discarded?",
  routeAbout: "About eBVD web",
  routeAdmin: "Admin",
  routeImport: "Importera",
  routeCreateAccount: "Create Account",
  CreateAccount: "Create Account",
  routeDocuments: "Search & manage eBVD",
  routeHome: "Home",
  routePublicSearch: "Search documents",
  routeRegistration: "Registation",
  routeTransactions: "Transactions",
  saveButtonText: "Save",
  searchBSAB96FilterTitle: "BSAB96",
  searchDocumentArticleIdentity: "ArticleIdentity: ",
  searchDocumentBK04: "BK04: ",
  searchDocumentBSAB96: "BSAB96: ",
  searchDocumentId: "Id: ",
  searchDocumentPublished: "Published: ",
  searchDocumentCreated: "Created: ",
  searchDocumentUpdated: "Last updated: ",
  searchDocumentStatus: "DOCUMENT STATUS",
  searchDocumentStatusCreated: "Created",
  searchDocumentStatusDeleted: "Deleted",
  searchDocumentStatusPublished: "Published",
  searchDocumentSupplier: "Supplier: ",
  searchDocumentValidFilter: "DOCUMENT VALID",
  searchDocumentVersion: "Version ",
  searchIncludeOldVersion: " Include Old version",
  searchIncludeOldVersionTitle: "INCLUDE OLD VERSION",
  searcheBVDPageTitle: "Search eBVD",
  searcheBVDTitle: "Search eBVD",
  sectionArticleInformation: "Article information",
  sectionChemicalContent: "Chemical Content",
  sectionCompanyCertificationTitle: "Company's Certification",
  sectionCompanyInformation: "Company information",
  sectionCompanyManagementTitle: "Management system",
  sectionCompanyPoliciesTitle: "Policies and guidelines",
  sectionCompanyTitle: "Company",
  sectionConstructionPhase: "Construction Phase",
  sectionDemolition: "Demolition",
  sectionDistribution: "Distribution",
  sectionDocumentData: "Document Data",
  sectionEnvironmentalImpact: "Environmental impact",
  sectionIndoorEnvironment: "Indoor Environment",
  sectionRawMaterials: "Raw Material",
  sectionSystemInformation: "System Information",
  sectionTotalRecycledMaterial: "Total recycled material in the article",
  sectionUsagePhase: "Usage Phase",
  sectionWasteManagement: "Waste Management",
  sections: "Sections",
  sessionTitleSubstanceProperties: "Substance properties",
  sortFilterDocumentUpdatedTimeAsc: "Least recently updated",
  sortFilterDocumentUpdatedTimeDesc: "Most recently updated",
  sortFilterDocumentVersion: "Document version",
  sortFilterDocumentIdAsc: "eBVD ID A-Z",
  sortFilterDocumentIdDesc: "eBVD ID Z-A",
  sortFilterProductNameAsc: "ProductName A-Z",
  sortFilterProductNameDesc: "ProductName Z-A",
  sortFilterTitle: "Sort by",
  storageRequirements: "If yes, please specify",
  supplierFilterChip: "Supplier: ",
  supplierFilterTitle: "SUPPLIER",
  tempSearchCreateNew: "Create new",
  tempSearchLabel: "Search document",
  tempSearchOpen: "Open",
  tempSearchShowPDF: "Show PDF",
  tempSupplierSearchLabel: "Search supplier",
  toastDeleted: "Deleted successfully",
  toastPublish: "Published sucessfully",
  toastSaved: "was saved",
  toastUnPublish: "was sucessfully unpublished",
  toastUpdated: "Updated successfully",
  toolBarCreatePDF: "Create PDF",
  toolBarNewVersion: "New version",
  toolBarPublish: "Publish",
  toolBarSave: "Save",
  CreateAccountSubmit: "Submit",
  toolBarUnPublish: "Unpublish",
  toolTipDownloadPDF: "Download PDF",
  toolTipGoToDocument: "Go to document",
  toolTipPublish: "Publish",
  toolTipUnPublish: "Unpublish",
  totalRecycleEditAttachment: "Edit included recycled material",
  totalRecycleMateialTableRecycle: "Recycled Material",
  totalRecycleMaterialArticle:
    "Is recycled and / or recycled material included in the product? If yes, fill in the table below",
  totalRecycleMaterialComment: "Comment",
  totalRecycleMaterialModalComment: "Comment",
  totalRecycleMaterialModalProportionWeight:
    "Enter the proportion of the total weight of the article made up by the recycled material[% or g]",
  totalRecycleMaterialModalRecycled: "Recycled Material",
  totalRecycleMaterialModalSharePercentage: "Share (%) waste (from other people's production)",
  totalRecycleMaterialModalUnit: "Unit",
  totalRecycleMaterialNotPassedConsumer:
    "Proportion(%) of the recycled material that has not passed the consumer stage(preconsumer)",
  totalRecycleMaterialPassedConsumer:
    "Proportion(%) of the recycled material that has passed the consumer stage(preconsumer) ",
  totalRecycleMaterialTableMaterialHeading: "Material",
  totalRecycleMaterialTableProportionAfter: "Proportion after the consumer stage",
  totalRecycleMaterialTableProportionBefore: "Proportion before the consumer stage",
  totalRecycleMaterialTableProportionOfWaste: "Proportion of waste (from another's production)",
  totalRecycleMaterialTableTitle:
    "If yes, please provide what percentage of the material (or item) in question is recycled",
  totalRecycleMaterialWeight: "Weight/percent by weight",
  totalRecycledMaterialModalMaterialTitle: "Material",
  transactions: "Transactions",
  transactionCreated: "Created",
  transactionPageTitle: "Publishing/Avpublishing",
  transactionResultMessage: "Result message",
  transactionStatus: "Status",
  unsavedDocumentPromptMessage: "You have unsaved changes. Are you sure you want to leave?",
  unpublishDocumentTitle: "Unpublish eBVD/eBVDs",
  unpublishDocumentsMessage: "Are you sure you want to unpublish: ",
  usagePhaseComment: "Comment",
  usagePhaseEnergyLabelForProduct:
    "Is there energy labelling under the Energy Labelling Directive (2010/30/EU) for the article?",
  usagePhaseEnergySupply: "Does the article have requirements for energy supply for operation?",
  usagePhaseEnterLabelTitle: "If yes enter labelling(G to A, A+, A++, A+++)",
  usagePhaseEnterLabelTitleTwo: "If yes enter labelling(G to A)",
  usagePhaseOperationSpecify: "If yes, please specify",
  usagePhaseOpertaionTitle: "Does the article require input materials for operation and maintenance?",
  usagePhaseOtherInformation: "Other information",
  usagePhaseTechnicalServiceLife:
    "Estimate technical service life for the article, enter according to one of the options a) or b) below: ",
  usagePhaseTechnicalServiceLifeEstimate: "a) The reference service life is estimated to be approximately",
  usagePhaseTechnicalServiceLiftEstimate: "b) The reference service lift is estimated to be in the range [years]",
  usagePhaseYears: "years",
  userAccepted: "Accepted",
  userCreated: "Created",
  userDetailOrganization: "Organization",
  userDetailUser: "User",
  userEMail: "E-mail",
  userFieldGroup: "Field Group",
  userInformationAccess: "Information Access",
  userName: "Name",
  userNotified: "Notified",
  userPhoneNo: "Phone Number",
  userRole: "Role",
  userRoleAdmin: "Administrator",
  userRoleCreateNew: "Can create new",
  userRoleCreateNewVersion: "Can create new version",
  userRoleDelete: "Can delete",
  userConfidentiality: "Confidentiality",
  userRoleEdit: "Can Edit",
  userRolePublish: "Can publish",
  userRoleRead: "Can Read",
  userRoleUnpublish: "Can unpublish",
  digitalTransferOrganization: "Organization",
  digitalTransferWebApiConsumer: "Web API Consumer",
  digitalTransferAddress: "Address",
  digitalTransferHomePage: "Home Page",
  digitalTransferReference: "Reference",
  digitalTransferPhoneNumber: "Phone Number",
  digitalTransferEMail: "E-mail",
  fieldUserPassword: "Password",
  roleCanEditOrganization: "Can edit organization",
  userRoles: "Roles",
  userSent: "Sent",
  userStatus: "Status",
  wasteManagementDeliveredTableTitle: "Add waste code",
  wasteManagementDeliveredTableWastecode: "Waste code",
  wasteManagementMountedTableTitle: "Add waste code",
  wasteManagementMountedTableWastecode: "Waste code",
  wasteMaterialComment: "Other information",
  wasteTreatmentTabMountedGroupBoxInfoText:
    "If the chemical composition of the article is different after mounting than on delivery, and the mounted article therefore has a different waste code, enter it here. If it is unchanged, omit information below.",
  welcomeSectionContent:
    "The eBVD-system provides manufacturers of construction products and materials with a digital tool for registration and handling of digital environmental information. The information required in an eBVD (electronic building declaration) is agreed upon by the whole construction industry. eBVD i Norden AB is a subsidiary to IVL Swedish Environmental Research Institute and the Swedish Association for Construction Products.",
  welcomeSectionContentToHomepage: "For further information about eBVD and the company’s history please see ",
  welcomeSectionContentAbouteBVD:
    "For information and user manuals about how to use the eBVD-system, as well as user terms and privacy policy",
  welcomeSectionQuestionsAbouteBVD: "For questions or inquiries about eBVD - please contact us at",
  welcomeSectionTitle: "Welcome to eBVD",
  welcomeSectionAdditionalInfo: "For further information about eBVD and the company’s history please see ",
  woodCertified: "Is the product available to buy certified?",
  woodCertifiedPart: "How large a porportion is certified [%]",
  woodCertifiedSystem: "What certification system has been used (for example FSC, CSA, SFI with CoC, PEFC)?",
  woodCertifiedSystemReference: "Reference Number",
  woodLoggingCountry: "Enter logging country for the wooden raw material and that following criteria have been met.",
  woodenRawMaterial: "Wooden Raw Materials",
  userAdd: "Add user to your organization",
  allInformation: "All information",
  noConfidentialInformation: "No confidential information",
  minimumInformation: "Minimum information",
  selectedInformation: "Selected information",
  tooltipInviteUser: "Invite user",
  tooltipEditUser: "Edit user",
  tooltipDeleteUser: "Delete user",
  tooltipReinviteUser: "Reinvite",
  tooltipDeleteUserInvitation: "Delete invitation",
  tooltipEditDigitalTransfer: "Edit digital Transfer",
  tooltipBulkManagement: "Use the checkboxes to manage multiple documents",
  registrationTitle: "Accept registration",
  registrationSuccessTitle2: "Thank you for registering to eBVD!",
  registrationSuccessTitle: "Registration request sent",
  accountInvoiceInformation: "Invoice Information",
  accountContactInformation: "Contact Information",
  accountInvoiceCountry: "Invoice Country",
  accountInvoiceAddress: "Invoice Address",
  accountPostCode: "Post Code",
  accountInvoiceReference: "Invoice Reference",
  accountInvoiceEmail: "Invoice Email",
  accountInternalOrganizationID: "Internal OrganizationID",
  accountVisitingAddress: "Visiting Address",
  accountReference: "Reference",
  accountEmail: "Email",
  accountTurnover: "Turnover",
  accountNumberOfBVDs: "Number of BVDs",
  accountDirectMemberOfByggmaterialindustrierna: "Direct member of Byggmaterialindustrierna Member of",
  accountTheFlooringIndustry: "The flooring industry",
  accountIKEMTheInnovationAndChemicalIndustries: "IKEM – The innovation and chemical industries",
  accountNordicGalvanizers: "Nordic Galvanizers",
  accountScandinavianCopperDevelopmentAssociationSCDA: "Scandinavian Copper Development Association SCDA",
  accountTheForestIndustries: "The forest industries",
  accountTheSteelConstructionInstitute: "The Steel Construction Institute",
  accountSwedisol: "Swedisol",
  accountSwedishVentilation: "Swedish Ventilation",
  accountSwedishConcrete: "Swedish Concrete",
  accountSwedenMiningIndustry: "Sweden's Mining Industry",
  accountSwedenFärgfabrikantersAssociation: "Sweden's Paint Manufacturers Association",
  accountSwedenFogAndGlueSuppliers: "Sweden's Fog and glue suppliers",
  accountTheWoodAndFurnitureCompanies: "The wood and furniture companies",
  accountTheSwedishRefrigerationAndHeatPumpAssociation: "The Swedish Refrigeration and Heat Pump Association",
  accountSwedishByggplåt: "Swedish Building plate",
  register: "Register",
  registrationConfirmationTitle: "You are now registered!",
  registrationConfirmationSubTitle: "Log in with your email address",
  registrationConfirmationMessage:
    "The first time you log in to the tool, click on the other password link and follow the instructions to get your password registered. If you've already logged in, click the eBVD logo top left to get to the start page.",
  documentAlert:
    "To download a PDF of a specific eBVD click its header in the list. Please login to get more information or to make changes.",
  manageeBVD: "Search & manage eBVD",
  searcheBVD: "Search eBVD",
  FAQ: "FAQ",
  userManual: "User manual",
  instructions: "Instructions",
  instructionsImportTemplate: "Instruction import functionality",
  privacyPolicy: "Privacy policy",
  userTerms: "User terms",
  fees: "Fees",
  registrationSuccessText1:
    "After we have received your registration, we will send a confirmation e-mail with a license agreement attached, as well as information regarding fees. In that document you can find which fee that applies to your company. As a final step, let a signatory or otherwise authorized person sign and return the scanned agreement to: ",
  registrationSuccessText2:
    "When we have received the signed agreement, we will activate your user account in the system and send your login information to you. After this you can start registering and administering your eBVD:s.",
  registrationSuccessText3:
    "If you require further information about how to connect to eBVD, please contact our support: ",
  toHomePage: "Get back to startpage",
  routeRegistrationSuccess: "Registration request sent",
  headerLinkCreateAccount: "Create account",
  toastDTDeleted: "Removed",
  toastDTSaved: "Saved",
  abouteBVDPageTitle: "About eBVD web",

  cookieMessage:
    "We use cookies (device identifiers) to customize our content to you, offer automatic translation and analyze our traffic. You can read more about how we protect your privacy in our privacy policy.",
  cookieLink: "cookieLink",
  cookiePolicyLinkText: "Integrity policy",
  cookieNecessaryOptionText: "Necessary",
  cookiePreferenceOptionText: "Settings",
  cookieStatisticsOptionText: "Statistics",
  cookieMarketingOptionText: "cookieMarketingOptionText",
  cookieManageCookieButton: "Manage cookies",
  cookieSaveCookiePref: "Save and close",
  cookieAcceptButton: "I agree",
  cookieDeclineButton: "cookieDeclineButton",
  articleDeclarationOfPerformanceNumberSubtitle:
    "Has a declaration of performance, according to the Construction Products Regulation, been drawn up for the product?",
  usagePhaseCommentError: "Enter the estimated technical lifetime of the item, or a comment",
  errorMinimumOneMessage: "At least 1 item in list above",
};
